import { useQuery } from '@tanstack/react-query';
import { getFactSheetDemoFile, getFactSheetOverview } from './factsheet.routes';

export function useFactSheetOverviewData() {
  return useQuery({
    queryKey: ['facts-sheet-overview-data'],
    queryFn: getFactSheetOverview,
  });
}

export function useFactSheetDemoPDF(index = 0, enabled = false) {
  return useQuery({
    queryKey: ['get-demo-fact-sheets', index],
    queryFn: getFactSheetDemoFile,
    enabled, // Used for handle button click to fetch data
  });
}
