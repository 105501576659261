export enum RootRoutePaths {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  DYNAMIC_SECTION = '/:section',
  LOGIN = '/login',
  SIGNUP = '/signup',
  RESET_PASSWORD = '/reset-password',
  RAIDR = '/raidr',
  SETTINGS = '/settings',
  MY_ACCOUNT = '/settings/my-account',
  MANAGE_ACCOUNTS = '/settings/manage-accounts',
  SECURITY_SETTINGS = '/settings/security-settings',
  AUDIT_LOG = '/settings/audit-log',
  DOCUMENTATION = '/documentation',
}

export enum RaidrRoutePaths {
  ROOT = '/raidr',
  PRIIPS_KIDS_OVERVIEW = '/raidr/priips-kids-overview',
  UCITS_KIIDS_OVERVIEW_EDIT = '/raidr/ucits-kiids-overview-edit',
  UCITS_KIIDS_OVERVIEW_VIEW = '/raidr/ucits-kiids-overview-view',
  PRIIPS_KIDS_EDITOR = '/raidr/priips-kids-editor',
  UCITS_KIIDS_EDITOR = '/raidr/ucits-kiids-editor',
  PRIIPS_KIDS_REVIEW = '/raidr/priips-kids-review',
  UCITS_KIIDS_REVIEW = '/raidr/ucits-kiids-review',
  PRIIPS_KIDS_COMPARE_VERSIONS = '/raidr/priips-kids-compare-versions',
  UCITS_KIIDS_COMPARE_VERSIONS = '/raidr/ucits-kiids-compare-versions',
  PRIIPS_KIDS_VIEW = '/raidr/priips-kids-view',
  UCITS_KIIDS_VIEW = '/raidr/ucits-kiids-view',
  FACT_SHEETS = '/raidr/fact-sheets',
  FACT_SHEETS_OVERVIEW = '/raidr/fact-sheets-overview',
  FACT_SHEETS_VIEW = '/raidr/fact-sheets-overview/view',
  FACT_SHEETS_EDITOR = '/raidr/fact-sheets-editor',
  DELEGATED_ACTS_OVERVIEW = '/raidr/delegated-acts-overview',
  DELEGATED_ACTS_EDITOR = '/raidr/delegated-acts-editor',
  KIDS_FIGURES = '/raidr/kids-figures',
  NOT_FOUND = '/raidr/*',
}

export enum OverviewsRoutePaths {
  // Manco Section Overview Pages
  MANCO_DASHBOARD = '/:section/manco-dashboard',
  EMIR = '/:section/emir',
  ADEPA_EMIR = '/:section/adepa-emir',
  MANCO_EXPOSURE = '/:section/manco-exposure',
  MANCO_COUNTERPARTY_EXPOSURE = '/:section/manco-counterparty-exposure',
  MANCO_LIQUIDATION_TIME = '/:section/manco-liquidation-time',
  MANCO_EQUITY = '/:section/manco-equity',
  MANCO_FIXED_INCOME = '/:section/manco-fixed-income',
  MANCO_CIS = '/:section/manco-cis',
  HEDGE_DERIVATIVES_SELECTOR = '/:section/hedge-derivatives-selector',
  REPORT_DELIVERY_OVERSIGHT = '/:section/report-delivery-oversight',
  MANCO_POSITION_OVERSIGHT = '/:section/manco-position-oversight',
  HYBRID_BONDS_SELECTOR = '/:section/hybrid-bonds-selector',
  RESTRICTED_COMPANIES = '/:section/restricted-companies',
  COUTTS_MARKET_OVERVIEW = '/:section/coutts-market-overview',
  SANCTIONS_SCANNER = '/:section/sanctions-scanner',
  COVERAGE_RATIO = '/:section/coverage-ratio',
  KPI_MONITORING = '/:section/kpi-monitoring',

  // All Regulation Sections Overview Pages
  OVERVIEW = '/:section/overview',
  AIFMD_OVERVIEW = '/:section/aifmd-overview',
  AIFMD_OVERVIEW_LEGACY = '/:section/aifmd-overview-legacy',
  STATUS_CHANGE = '/:section/status-change',
  GLOBAL_EXPOSURE = '/:section/global-exposure',
  DECEASED = '/:section/deceased',
  NURS_DECEASED = '/:section/nurs-deceased',
  SIF_RAIF_DECEASED = '/:section/sif-raif-deceased',
  SRRI_SHARE_CLASS_OVERVIEW = '/:section/srri-share-class-overview',
  SHARE_CLASS_HEDGE_SUMMARY = '/:section/share-class-hedge-summary',
  QUAYSIDE_SHARE_CLASS_HEDGE = '/:section/quayside-share-class-hedge', // Actually has a custom fund picker as a hacky way to get custom funds into it
  LIQUIDITY_OVERVIEW = '/:section/liquidity-overview',
  AGGREGATE_LIQUIDATION_TIME = '/:section/aggregate-liquidation-time',
  FUNCTIONALITY = '/:section/functionality',
  RAPTOR_EXPORT = '/:section/raptor-export',
  RAPTOR_EXPORT_TODAY = '/:section/raptor-export-today',
  FIXED_INCOME_REPORT_GENERATOR = '/:section/fixed-income-report-generator', // Why is this a standalone page... silly, should be part of a centralised reports / exports page

  // Private Equity Section Overview Pages
  PE_OVERVIEW = '/:section/pe-overview',

  // ESG Section Overview Pages
  ESG_OVERVIEW = '/:section/esg-overview',

  // RAIDR Section Overview Pages

  // PA Section Overview Pages
  PA_OVERVIEW = '/:section/pa-overview',
  PA_OVERVIEW_ALTERNATIVE = '/:section/pa-overview-alternative',
  PA_REPORT_GENERATOR = '/:section/pa-report-generator',

  // Library Section Overview Pages
  LIBRARY = '/:section/library',

  // Markets Section Overview Pages
  MARKETS_OVERVIEW = '/:section/markets-overview',
  YIELD_CURVE = '/:section/yield-curve',

  // Miscellaneous / Uncategorized Overview Pages
}

export enum FundRoutePaths {
  // Manco Section Fund Level Pages
  HISTORICAL_DATA_EXPORT = '/:section/historical-data-export',
  POSITION_UPDATE_PORTAL = '/:section/position-update-portal',

  // Regulation Section Fund Level Pages
  AIFMD_REPORTS = '/:section/aifmd-reports',
  // UCITS_LAW = '/:section/ucits-law', // For some reason this is a different fund picker
  RIAIF_LAW = '/:section/riaif-law',
  ACT40_LAW = '/:section/act40-law',
  FIVG_LAW = '/:section/fivg-law',
  PARTII_LAW = '/:section/partii-law',
  NURS_LAW = '/:section/nurs-law',
  QIAIF_LAW = '/:section/qiaif-law',
  COUNTERPARTY = '/:section/counterparty',
  STRESS_SCENARIO_TESTS = '/:section/stress-scenario-tests',
  HISTORICAL_DATA = '/:section/historical-data',
  RISK_COMPARISON = '/:section/risk-comparison',
  COUNTERPARTY_STRESS_TESTS = '/:section/counterparty-stress-tests',
  VAR_BACKTEST = '/:section/var-backtest',
  SRRI = '/:section/srri',
  SRRI_SHARE_CLASS = '/:section/srri-share-class',
  SHARE_CLASS_HEDGE = '/:section/share-class-hedge',
  COLLATERAL_MONITOR = '/:section/collateral-monitor',
  SHARE_CLASS_HEDGE_SUMMARY = '/:section/share-class-hedge-summary',
  CSSF_RISK_SPREADING = '/:section/cssf-risk-spreading',
  RELATIVE_RISK = '/:section/relative-risk',
  FUND_ASSET_LIQUIDATION_TIME = '/:section/fund-asset-liquidation-time',
  MOAB = '/:section/moab',
  LASDAB = '/:section/lasdab',
  LIQUIDATION_RISK_OVERVIEW = '/:section/liquidation-risk-overview',
  RST_FIXED = '/:section/rst-fixed',
  RST_VARIABLE = '/:section/rst-variable',
  COUNTERPARTY_MARKET_LIQUIDITY_RISK = '/:section/counterparty-market-liquidity-risk',
  LIQUIDITY_RISK_EVOLUTION = '/:section/liquidity-risk-evolution',
  REVERSE_STRESS_TEST_EVOLUTION = '/:section/reverse-stress-test-evolution',
  SUBSCRIPTIONS_REDEMPTIONS = '/:section/subscriptions-redemptions',
  MARKET_LIQUIDITY_RISK = '/:section/market-liquidity-risk',
  VARIABLE_MARKET_VOLUME_LIQUIDATION_COSTS = '/:section/variable-market-volume-liquidation-costs',
  EQUITY = '/:section/equity',
  CURRENCY = '/:section/currency',
  FIXED_INCOME = '/:section/fixed-income',
  CIS = '/:section/cis',
  ALTERNATIVE_NON_CIS = '/:section/alternative-non-cis',
  NOTES = '/:section/notes',
  FAILS = '/:section/fails',
  HISTORICAL_FAILS = '/:section/historical-fails',
  SUB_FUND_SETUP = '/:section/sub-fund-setup',
  FX_RATES = '/:section/fx-rates',

  // Private Equity Section Fund Level Pages
  PE_EXPOSURE = '/:section/pe-exposure',
  // Prospectus Restrictions page also applies here
  DISCOUNT_CASHFLOW_OVERVIEW = '/:section/discount-cashflow-overview',
  DISCOUNT_CASHFLOW_CONFIGURATION = '/:section/discount-cashflow-configuration',
  CASHFLOW_STRESS_TESTS = '/:section/cashflow-stress-tests',
  PE_HEAT_MAP = '/:section/pe-heat-map',
  PE_MONTE_CARLO_VAR = '/:section/pe-monte-carlo-var',
  PE_PROXY_MODEL = '/:section/pe-proxy-model',
  PE_STRESS_SCENARIO_TESTS = '/:section/pe-stress-scenario-tests',
  PE_VALUE_AT_RISK = '/:section/pe-value-at-risk',
  PE_LIQUIDITY_DISCOUNT_ANALYSIS = '/:section/pe-liquidity-discount-analysis',
  LIQUIDITY_PE_VALUE_AT_RISK = '/:section/liquidity-pe-value-at-risk',
  PE_MARKET_RISK_OVERVIEW = '/:section/pe-market-risk-overview',

  // ESG Section Fund Level Pages
  PORTFOLIO_ESG = '/:section/portfolio-esg',
  ARTICLE_SCANNER = '/:section/article-scanner',
  PRINCIPLE_ADVERSE_IMPACT = '/:section/principle-adverse-impact',
  CARBON_EMISSIONS = '/:section/carbon-emissions',
  ENVIRONMENTAL_ESG = '/:section/environmental-esg',
  SOCIAL_ESG = '/:section/social-esg',
  CORPORATE_GOVERNANCE_ESG = '/:section/corporate-governance-esg',
  ESG_EXPOSURE = '/:section/esg-exposure',
  ESG_STRESS_TESTS = '/:section/esg-stress-tests',
  ESG_VAR = '/:section/esg-var',
  ESG = '/:section/esg',
  INDICATOR_COMPLIANCE = '/:section/indicator-compliance',
  INDICATOR_COVERAGE_RATIO_MONITOR = '/:section/indicator-coverage-ratio-monitor',
  ES_ALIGNMENT_MONITOR = '/:section/es-alignment-monitor',

  // RAIDR Section Fund Level Pages

  // PA Section Fund Level Pages
  PERFORMANCE_DIAGNOSTICS = '/:section/performance-diagnostics',
  SUB_FUND_PERFORMANCE = '/:section/sub-fund-performance',
  SUB_FUND_CUMULATIVE_BREAKDOWN = '/:section/sub-fund-cumulative-breakdown',
  ASSET_ALLOCATION_PERFORMANCE = '/:section/asset-allocation-performance',
  STOCK_SELECTION_PERFORMANCE = '/:section/stock-selection-performance',
  SUB_FUND_HISTORICAL_PERFORMANCE = '/:section/sub-fund-historical-performance',
  ASSET_AND_CURRENCY = '/:section/asset-and-currency',
  INITIAL_EXPOSURE_VS_CUMULATIVE_RETURNS = '/:section/initial-exposure-vs-cumulative-returns',
  SUB_FUND_VS_BENCHMARK = '/:section/sub-fund-vs-benchmark',

  // Library Section Fund Level Pages

  // Markets Section Fund Level Pages
  PERFORMANCE_REPORT = '/:section/performance-report',

  // Miscellaneous / Uncategorized Fund Level Pages
}

// These routes have custom fund picker pages, the plan is to generalise the fund picker and move these routes to the fund routes,
// but for now we will keep them here
export enum CombinedRoutePaths {
  VALUE_AT_RISK = '/:section/value-at-risk',
  UCITS_LAW = '/:section/ucits-law',
  EXPOSURE = '/:section/exposure',
  PROSPECTUS_RESTRICTIONS = '/:section/prospectus-restrictions',
  FAILS = '/:section/fails',
  SHARE_CLASS_HEDGE = '/:section/share-class-hedge',
  HISTORICAL_DATA_EXPORT = '/:section/historical-data-export',
}
