import React, { FC } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import CustomTable from '../../../tables/CustomTable';

export interface AssetCurrencyGrossExposureProps {
  dataForRender: DataObject;
  fundId: string;
  fundName: string;
}
export interface ICurrencyExposure {
  currency: string;
  cash: string;
  asset: string;
}
export function buildAssetCurrencyExposure(data: any): ICurrencyExposure[] {
  const cashBreakdown = data.cash_currency_breakdown || {};
  const assetCurrencyExposure = data.cash_asset_currency_exposure || {};
  
  const cashKeys = Object.keys(cashBreakdown);
  const assetKeys = Object.keys(assetCurrencyExposure);

  let combinedKeys = [...cashKeys, ...assetKeys];
  const setOfKeys = new Set(combinedKeys);

  combinedKeys = Array.from(setOfKeys);
  const finalData = combinedKeys.map((cashKey) => {
    return {
      currency: cashKey,
      cash: cashBreakdown[cashKey]
        ? convert(cashBreakdown[cashKey], data.nav)
        : '0%',
      asset: assetCurrencyExposure[cashKey]
        ? convert(assetCurrencyExposure[cashKey], data.nav)
        : '0%',
    };
  });
  return finalData.filter(
    (item: any) => !(item.cash === '0%' && item.asset === '0%'),
  );
}
function convert(val: number, nav: number) {
  return ((val / nav) * 100).toFixed(2) + '%';
}

export const useAssetCurrencyExposureColumns =
  (): CustomColumn<ICurrencyExposure>[] => {
    const generalStyle = {
      fontSize: '1.00rem',
      padding: '0.62rem',
    };
    return [
      {
        title: 'Currency',
        width: '33%',
        field: 'currency',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      {
        title: 'Cash',
        width: '33%',
        field: 'cash',
        headerStyle: { textAlign: 'center' },
        cellStyle: { ...generalStyle, textAlign: 'center' },
      },
      {
        title: 'Asset',
        field: 'asset',
        width: '33%',
        cellStyle: { ...generalStyle, textAlign: 'center' },
        headerStyle: { textAlign: 'center' },
      },
    ];
  };

const AssetCurrencyExposure: FC<AssetCurrencyGrossExposureProps> = (props) => {
  const { fundId, fundName } = props;
  const { data } = props.dataForRender;
  const columns = useAssetCurrencyExposureColumns();
  
  const renderedData = !data || !data.length || !data[0] 
    ? [] 
    : buildAssetCurrencyExposure(data[0]);
  
  const selectedPositionDate = data && data.length > 0 && data[0].selected_position_date 
    ? data[0].selected_position_date 
    : '';

  return (
    <CustomTable<ICurrencyExposure>
      selectedPositionDate={selectedPositionDate}
      multipleTablePdfParameters={{
        specifiedWidth: 4,
        tablesInRow: 3,
        groupName: 'exposure_page',
        groupOrder: 3,
      }}
      id={`${fundId}_asset_currency_exposure`}
      title="Asset Currency Exposure"
      showToolbar={true}
      loading={props.dataForRender.isFetching}
      csvFields={['currency', 'cash', 'asset']}
      toolbarComponents={{
        toolbarTitle: 'Asset Currency Exposure',
      }}
      columns={columns}
      data={renderedData}
      options={{
        paging: false,
        search: false,
        exportButton: true,
      }}
      pdfDontMoveToNewPage
    />
  );
};

export default AssetCurrencyExposure;
