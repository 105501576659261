import { AxiosInstance } from '../../../../../utilities/requestClient';
import {
  AuthorisedFundConfigType,
  PositionUpdatePortalFetchedData,
} from '../PositionsUpdatePortal.types';

export const getPositionUpdateConfigData = async () => {
  return (
    await AxiosInstance.get<AuthorisedFundConfigType>(
      'position_update-load_configs',
    )
  ).data;
};

export const getEditablePositionData = async (fundName: string) => {
  return (
    await AxiosInstance.get<PositionUpdatePortalFetchedData>(
      `position_update-load_positions/${fundName}`,
    )
  ).data;
};

export const updatePosition = async (data: any) => {
  return await AxiosInstance.post('position_update-update_positions/', data);
};
