import { greys } from '../../../../../../styling/theme';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const useStyles = makeStyles(() => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
}));

const DownloadPdfForm = ({
  pdfUrl,
  fileName,
}: {
  fileName: string;
  pdfUrl: string | null;
}) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      title="Download PDF"
      placement="top"
      arrow
      enterNextDelay={2000}
      enterDelay={2000}
    >
      <button
        onClick={() => {
          if (!pdfUrl) return;
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = `${fileName}.pdf`; // Desired filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }}
        className={`hover:bg-mainblue-dark flex items-center justify-center gap-2 rounded-sm bg-mainblue px-3 py-1 text-center text-xs text-white transition hover:bg-sky-800 focus:bg-sky-800 md:mx-auto`}
      >
        <span className="hidden font-bold md:inline">
          Download Document
          <DownloadForOfflineIcon className="ml-3 text-[1.25rem]" />
        </span>
        <DownloadForOfflineIcon className="text-[1.25rem] md:hidden" />
      </button>
    </Tooltip>
  );
};

export default DownloadPdfForm;
