import {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  FunctionComponent,
} from 'react';
import client from '../../../../../../utilities/requestClient';
import KidsEditorGridItem from '../../layout/KidsEditorGridItem.component';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import Raptor2Loading from '../../../../../feedback/Raptor2Loading';
import SaveConfirmationDialogBox from './SaveConfirmationDialogBox.component';
import useSnackbar from '../../../../../../hooks/useSnackbar';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { KidsData } from '../KidsEditor';
import { useGenerateNewKIDPdf } from '../../services/mutations';
import DownloadPdfForm from './DownloadPdfForm';
import { Document, Page } from 'react-pdf';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { TextField, InputAdornment } from '@mui/material';
import ErrorBoundaryMessage from '../../../../../ui/ErrorBoundaryMessage';
import { useEditorPdfViewerStyles } from '../../styles/KidsEditor.styles';
import { KidsDataPdfVersion } from '../../types/KidsData.types';
import { convertToPdfVersion } from '../../utils/viewer.utils';
import { getOS } from '../../../../../../utilities/helpers/common/system';
import { useRaidrKids } from '../../context/RaidrKidsContext';
import { pdfjs } from 'react-pdf';

interface EditorPdfViewerProps {
  priipsKidsData: KidsData[];
  isSaveEnabled: boolean;
  getTemplateChangesMade: () => string[];
  getCanUserSave: () => string[];
  resetContentAfterSave: () => void;
  figuresWereUpdated: boolean;
  fileName: string;
}

export interface priipsKidsDataPdfVersion {
  format_options: string;
  manco_id: string;
  tag: string;
  content: string;
  version: string;
  edited_by_name: string;
  edit_timestamp: string;
  edited_by: string;
  fund_name: string;
  comment_id: string | null;
  field_id: string;
  published_by: string;
  kiid_id: string;
  kiid_index: number;
  share_class_name: string;
  share_class: string;
  fund_id_string: string;
  has_unresolved_comment: boolean;
  document_language: string;
  published_by_name: string;
  fund_id: string;
  is_published: boolean;
}

const EditorPdfViewer = forwardRef<
  { refreshPdf: () => void },
  EditorPdfViewerProps
>(
  (
    {
      priipsKidsData,
      isSaveEnabled,
      getTemplateChangesMade,
      getCanUserSave,
      resetContentAfterSave,
      figuresWereUpdated,
      fileName,
    },
    ref,
  ) => {
    const { iframeTitle } = useRaidrKids(); // Not currently used - but maybe we'll add a title to the viewer again after more design planning
    const requestClient = client();
    const { showAsyncSnackbar } = useSnackbar();
    const classes = useEditorPdfViewerStyles();
    const createNewKIDPdf = useGenerateNewKIDPdf();
    const [saveDialogIsShowing, setSaveDialogIsShowing] = useState(false);

    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [numPages, setNumPages] = useState<number>(0);
    const pdfContainerRef = useRef<HTMLDivElement | null>(null);
    const scrollPosRef = useRef<number>(0);

    const OS = getOS();

    // Add error boundary state
    const [hasError, setHasError] = useState(false);

    // Expose refreshPdf to parent
    useImperativeHandle(ref, () => ({
      refreshPdf,
    }));

    // Initial load
    useEffect(() => {
      refreshPdf();
    }, []);

    const onDocumentLoadSuccess = (pdf: any) => {
      setNumPages(pdf.numPages);
      // Once PDF is loaded, restore scroll position:
      if (pdfContainerRef.current) {
        pdfContainerRef.current.scrollTop = scrollPosRef.current;
      }
    };

    // FUNCTIONS
    const refreshPdf = async () => {
      if (!priipsKidsData?.length) return;

      try {
        setHasError(false);
        const content = priipsKidsData.map(convertToPdfVersion);
        const formData = new FormData();
        const priipsKidsJson = JSON.stringify(content);
        const fundId = content[0].fund_id_string;
        const fundName = content[0].fund_name;
        const isin = content[0].share_class;
        const documentLanguage = content[0].document_language;

        formData.append('contents', priipsKidsJson);
        formData.append('fund_id', fundId);
        formData.append('fund_name', fundName);
        formData.append('isin', isin);
        formData.append('document_language', documentLanguage);
        formData.append(
          'params',
          JSON.stringify([
            'contents',
            'fund_id',
            'fund_name',
            'isin',
            'document_language',
          ]),
        );

        const response = await createNewKIDPdf.mutateAsync(formData);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileUrl = window.URL.createObjectURL(blob);

        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = fileUrl;
          iframe.title = iframeTitle;
        }

        if (pdfUrl) URL.revokeObjectURL(pdfUrl);

        // Setting the worker for pdfjs:
        if (OS != 'linux') {
          pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
        }

        setPdfUrl(fileUrl);
      } catch (error) {
        console.error('Failed to refresh PDF:', error);
        setHasError(true);
      }
    };

    async function checkTemplateChanges() {
      const formData = new FormData();
      const fieldIds = getTemplateChangesMade();
      if (fieldIds.length) {
        const changes: any[] = [];
        fieldIds.forEach((id) => {
          const element = priipsKidsData.find((elem) => elem.fieldId === id);
          changes.push({
            template_id: element?.templateId,
            text: element?.content,
          });
        });
        formData.append('template_updates', JSON.stringify(changes));
      } else {
        return [];
      }

      let errorOccured = false;
      let affectedDocuments: string[] = [];
      await requestClient
        .post('get_documents_affected_by_template_changes', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        .catch((error) => {
          alert('There was an error while retrieving affected documents.');
          errorOccured = true;
          throw error;
        })
        .then((response) => {
          affectedDocuments = response.data;
        });
      return affectedDocuments;
    }

    async function handleSaveChanges(
      applyToAllDocuments: boolean,
      applyToAllShareClasses?: boolean,
    ) {
      if (!priipsKidsData.length) {
        return;
      }
      const content: KidsDataPdfVersion[] = [];
      priipsKidsData.forEach((element: any) => {
        content.push(convertToPdfVersion(element));
      });

      const formData = new FormData();
      formData.append('kiid_data', JSON.stringify(content));

      const fieldIds = getTemplateChangesMade();
      if (fieldIds.length && applyToAllDocuments) {
        const changes: any[] = [];
        fieldIds.forEach((id) => {
          const element = priipsKidsData.find((elem) => elem.fieldId === id);
          changes.push({
            template_id: element?.templateId,
            text: element?.content,
          });
        });
        formData.append('template_updates', JSON.stringify(changes));
      }

      let errorOccured = false;
      await showAsyncSnackbar({
        startMessage: 'Saving Data.',
        successMessage: 'Data Saved.',
        failureMessage: 'An Error occured while saving.',
        promiseToResolve: requestClient
          .post('kiid_generator_write_kiid_data', formData, {
            headers: { 'content-type': 'multipart/form-data' },
          })
          .catch((error) => {
            alert('Error while saving changes.');
            errorOccured = true;
            throw error;
          })
          .finally(() => {
            if (!errorOccured) resetContentAfterSave();
          }),
      });

      if (applyToAllShareClasses) {
        await handleUpdateFigures(applyToAllShareClasses);
      }
      refreshPdf();
    }

    async function handleUpdateFigures(applyToAllShareClasses: boolean) {
      const formData = new FormData();

      formData.append('fund_id', priipsKidsData[0].fundIdString);
      formData.append('fund_uuid', priipsKidsData[0].fundId);
      formData.append('excluded_share_class', priipsKidsData[0].shareClass);
      formData.append(
        'only_apply_to_sepcified_share_class',
        applyToAllShareClasses ? 'no' : 'yes',
      );

      let errorOccured = false;
      await showAsyncSnackbar({
        startMessage: 'Updating Figures.',
        successMessage: 'Figures Updated Successfully.',
        failureMessage: 'An Error occured while updating figures.',
        promiseToResolve: requestClient
          .post('kiid_generator_refresh_figures_data', formData, {
            headers: { 'content-type': 'multipart/form-data' },
          })
          .catch((error) => {
            alert('Error while updating figures.');
            errorOccured = true;
            throw error;
          })
          .finally(() => {
            if (!errorOccured) resetContentAfterSave();
          }),
      });
      refreshPdf();
    }

    // Add zoom state
    const [scale, setScale] = useState(1);
    const [customZoom, setCustomZoom] = useState('100');
    const MIN_SCALE = 0.25;
    const MAX_SCALE = 4.0;
    const SCALE_STEP = 0.1;

    const handleZoomIn = () => {
      const newScale = Math.min(scale + SCALE_STEP, MAX_SCALE);
      setScale(newScale);
      setCustomZoom(Math.round(newScale * 100).toString());
    };

    const handleZoomOut = () => {
      const newScale = Math.max(scale - SCALE_STEP, MIN_SCALE);
      setScale(newScale);
      setCustomZoom(Math.round(newScale * 100).toString());
    };

    const handleResetZoom = () => {
      setScale(1);
      setCustomZoom('100');
    };

    const handleCustomZoomChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event.target.value.replace(/[^\d]/g, '');
      setCustomZoom(value);
    };

    const handleCustomZoomBlur = () => {
      let numValue = parseInt(customZoom, 10);
      if (isNaN(numValue)) numValue = 100;

      numValue = Math.max(Math.min(numValue, MAX_SCALE * 100), MIN_SCALE * 100);
      const newScale = numValue / 100;

      setScale(newScale);
      setCustomZoom(numValue.toString());
    };

    const handleCustomZoomKeyPress = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleCustomZoomBlur();
      }
    };

    // Update the loading UI
    const renderLoadingUI = (
      <div className={classes.loadingContainer}>
        <Raptor2Loading centerWrap messages={['Generating Document...']} />
      </div>
    );

    // Add near other event handlers
    const handleScroll = () => {
      if (pdfContainerRef.current) {
        scrollPosRef.current = pdfContainerRef.current.scrollTop;
      }
    };

    return (
      <KidsEditorGridItem xs={12}>
        <div className={classes.viewerContainer}>
          {/* REFACTOR - ELEMENT DIFF/ LARGE FILES HARD */}

          <div className={classes.toolbar}>
            {/* Left group - Zoom controls */}
            {OS != 'Linux' && (
              <div className={classes.toolbarGroup}>
                <div className={classes.zoomControls}>
                  <Tooltip title="Zoom Out" arrow placement="top">
                    <span>
                      <Button
                        className={classes.zoomButton}
                        variant="outlined"
                        onClick={handleZoomOut}
                        disabled={scale <= MIN_SCALE}
                      >
                        <ZoomOutIcon fontSize="small" />
                      </Button>
                    </span>
                  </Tooltip>

                  <TextField
                    className={classes.zoomInput}
                    value={customZoom}
                    onChange={handleCustomZoomChange}
                    onBlur={handleCustomZoomBlur}
                    onKeyDown={handleCustomZoomKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    size="small"
                  />

                  <Tooltip title="Zoom In" arrow placement="top">
                    <span>
                      <Button
                        className={classes.zoomButton}
                        variant="outlined"
                        onClick={handleZoomIn}
                        disabled={scale >= MAX_SCALE}
                      >
                        <ZoomInIcon fontSize="small" />
                      </Button>
                    </span>
                  </Tooltip>

                  <Tooltip title="Reset Zoom" arrow placement="top">
                    <span>
                      <Button
                        className={classes.zoomButton}
                        variant="outlined"
                        onClick={handleResetZoom}
                        disabled={scale === 1}
                      >
                        <RestartAltIcon fontSize="small" />
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            )}
            {/* Right group - Action buttons */}
            <div
              style={{ marginLeft: 'auto' }}
              className={classes.toolbarGroup}
            >
              <Button
                className={classes.toolbarButton}
                variant="outlined"
                onClick={refreshPdf}
                startIcon={<RefreshIcon sx={{ fontSize: '0.94rem' }} />}
                disableElevation
              >
                Reload
              </Button>
              {saveDialogIsShowing && (
                <SaveConfirmationDialogBox
                  setDialogIsShowing={setSaveDialogIsShowing}
                  dialogIsShowing={saveDialogIsShowing}
                  getTemplateChangesMade={getTemplateChangesMade}
                  getCanUserSave={getCanUserSave}
                  handleSaveChanges={handleSaveChanges}
                  figuresWereUpdated={figuresWereUpdated}
                  handleUpdateFigures={handleUpdateFigures}
                  checkTemplateChanges={checkTemplateChanges}
                />
              )}
              <Button
                className={
                  isSaveEnabled ? classes.primaryButton : classes.toolbarButton
                }
                variant={isSaveEnabled ? 'contained' : 'outlined'}
                onClick={() => setSaveDialogIsShowing(true)}
                disabled={!isSaveEnabled}
                startIcon={<SaveIcon sx={{ fontSize: '0.94rem' }} />}
                disableElevation
              >
                Save
              </Button>

              <DownloadPdfForm pdfUrl={pdfUrl} fileName={fileName} />
            </div>
          </div>

          <div
            className={classes.iframeContainer}
            ref={pdfContainerRef}
            onScroll={handleScroll}
          >
            {hasError ? (
              <div className={classes.errorContainer}>
                <ErrorBoundaryMessage />
              </div>
            ) : (
              <>
                {OS == 'Linux' ? (
                  <TempIframeFallback isPending={createNewKIDPdf.isPending} />
                ) : (
                  <>
                    {(createNewKIDPdf.isPending || !pdfUrl) && renderLoadingUI}
                    {pdfUrl && !hasError && (
                      <Document
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={() => setHasError(true)}
                        loading={renderLoadingUI}
                        className={classes.documentContainer}
                      >
                        {Array.from(new Array(numPages), (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            className={classes.pageContainer}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            width={800 * scale}
                            renderMode="canvas"
                          />
                        ))}
                      </Document>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </KidsEditorGridItem>
    );
  },
);

export default EditorPdfViewer;

interface TempIframeFallbackProps {
  isPending: boolean;
}

const TempIframeFallback: FunctionComponent<TempIframeFallbackProps> = ({
  isPending,
}) => {
  return (
    <>
      <iframe
        src=""
        width={isPending ? '0%' : '100%'}
        height="100%"
        title={'kid.pdf'}
      ></iframe>
      {isPending ? (
        <Raptor2Loading centerWrap messages={['Generating Document...']} />
      ) : null}
    </>
  );
};
