import React, { useState, useEffect } from 'react';
import { SettingsComponentProps } from '../../SettingsRouter.component';
import { FiChevronDown, FiX } from 'react-icons/fi';
import { useQuery } from '@tanstack/react-query';
import client from '../../../../utilities/requestClient';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import RaptorDatePicker from '../../../../components/selects/RaptorDatePicker.component';
import dayjs from 'dayjs';
import { getAllRelevantDates } from '../../../../utilities/dateUtilities';
import ExportButton from '../../../feedback/ExportButton';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../redux/fileExport/actions';
import { PdfComponentType } from '../../../../types/redux/pdfExports/FileExportsStore';

// Add a modal component for displaying full values
const ValueModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  value: string;
}> = ({ isOpen, onClose, title, value }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[80vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg font-medium text-mainblue">{title}</h3>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FiX size={20} />
          </button>
        </div>
        <div className="p-4 overflow-auto flex-grow">
          <pre className="whitespace-pre-wrap break-words text-sm">{value}</pre>
        </div>
        <div className="border-t p-4 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-mainblue text-white rounded hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

interface AuditLogEntry {
  endpoint_execution_datetime: string;
  user_name: string;
  module_name: string;
  app_name: string;
  operation: string;
  page_name: string;
  attribute_key: string;
  value_before_change: string;
  value_after_change: string;
  client_id: string;
  client_name: string;
  client_name_full: string;
  fund_name: string;
  key_path: string;
  year: number;
  month: number;
  page_id: string;
  position_date: string;
  position_id: string;
  rs_aggregated_route_id: string;
  rs_route_id: string;
  underlying_table_name: string;
  user_id: string;
}

const AuditLog: React.FC<SettingsComponentProps> = () => {
  const dispatch = useDispatch();
  // Move state inside the component
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageInputValue, setPageInputValue] = useState('');
  
  // Add state for date range - using Date objects for DatePicker
  const [startDate, setStartDate] = useState<Date>(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000) // 30 days ago
  );
  const [endDate, setEndDate] = useState<Date>(
    new Date() // today
  );
  
  // Add state for the modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalValue, setModalValue] = useState('');
  
  const fetchAuditLogData = async () => {
    try {
      const requestClient = client();
      // Format dates for API request
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];
      
      const response = await requestClient.get('rs_audit_log/audit_log/get_audit_log/', {
        params: {
          start_date: formattedStartDate,
          end_date: formattedEndDate
        }
      });
      // Ensure we always return an array, even if the API returns null or undefined
      const data = Array.isArray(response.data) ? response.data : [];
      // Sort by timestamp in descending order (most recent first)
      return data.sort((a, b) => 
        new Date(b.endpoint_execution_datetime).getTime() - new Date(a.endpoint_execution_datetime).getTime()
      );
    } catch (err) {
      console.error('Error fetching audit log data:', err);
      return []; // Return empty array on error
    }
  };
  
  // Update query to depend on date range
  const { data: auditLogs = [], isLoading, error } = useQuery({
    queryKey: ['auditLogData', startDate.toISOString(), endDate.toISOString()],
    queryFn: fetchAuditLogData,
    refetchOnWindowFocus: false
  });
  
  // Handle date changes
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setStartDate(date);
    }
  };
  
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setEndDate(date);
    }
  };

  useEffect(() => {
    if (error) {
      console.error('Error fetching audit log data:', error);
    }
  }, [error]);
  
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    setPageInputValue('');
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPageInputValue('');
  };

  const handlePageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageInputValue(event.target.value);
  };

  const handlePageInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const pageNumber = parseInt(pageInputValue, 10);
      if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= totalPages) {
        handleChangePage(pageNumber - 1); // Convert from 1-based to 0-based
      }
    }
  };

  // Calculate total pages based on actual data
  const totalPages = Math.ceil((auditLogs?.length || 0) / rowsPerPage);

  // Function to open the modal with a value
  const openValueModal = (title: string, value: string) => {
    setModalTitle(title);
    setModalValue(value);
    setModalOpen(true);
  };

  // Add PDF export handler
  const tablePdfExportHandler = async () => {
    const renderColumns = [
      { header: 'Timestamp', dataKey: 'endpoint_execution_datetime' },
      { header: 'User', dataKey: 'user_name' },
      { header: 'Operation', dataKey: 'operation' },
      { header: 'Page', dataKey: 'page_name' },
      { header: 'Attribute', dataKey: 'attribute_key' },
      { header: 'Before Change', dataKey: 'value_before_change' },
      { header: 'After Change', dataKey: 'value_after_change' },
      { header: 'Client Name', dataKey: 'client_name' },
      { header: 'Fund Name', dataKey: 'fund_name' },
      { header: 'Key Path', dataKey: 'key_path' }
    ];

    return {
      startY: 50,
      columns: renderColumns,
      body: auditLogs
    };
  };

  // Register component for PDF export when data changes
  useEffect(() => {
    if (auditLogs && auditLogs.length > 0) {
      dispatch(
        addComponentToPdfExport({
          identifier: `audit_log_table_${dayjs(startDate).format('YYYY-MM-DD')}_to_${dayjs(endDate).format('YYYY-MM-DD')}`,
          handler: tablePdfExportHandler,
          type: PdfComponentType.TABLE,
          title: `Audit Log (${dayjs(startDate).format('YYYY-MM-DD')} to ${dayjs(endDate).format('YYYY-MM-DD')})`,
          dontMoveToNewPage: true,
        })
      );
    }
  }, [auditLogs, dispatch, startDate, endDate]);

  return (
    <GeneralComponentErrorShield
      dataObjects={[]}
      customLoadingMessages={[
        'Loading Audit Log Data...',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Audit Log Data Available."
    >
      <div className="w-full flex flex-col p-1.5 gap-2">
        <div className="bg-white rounded-md shadow-md border border-gray-200 p-4">
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-xl font-bold text-mainblue underline">
              Audit Log
            </h1>
          </div>
          <p className="text-base text-mainblue mb-4">
            View a history of changes made to system data and settings. This is an alpha feature and is subject to change/updates. Please report any irregularities found.
          </p>
          
          {/* Date range filter with RaptorDatePicker */}
          <div className="flex gap-4 bg-mainblue bg-opacity-10 rounded-md border border-mainblue border-opacity-20 w-fit">
            <div className="flex-shrink-0">
              <RaptorDatePicker
                title="Start Date:"
                selectedDate={dayjs(startDate)}
                datesToInclude={getAllRelevantDates()}
                handler={handleStartDateChange}
              />
            </div>
            <div className="flex-shrink-0">
              <RaptorDatePicker
                title="End Date:"
                selectedDate={dayjs(endDate)}
                datesToInclude={getAllRelevantDates()}
                handler={handleEndDateChange}
              />
            </div>
          </div>
        </div>
        
        {/* Display loading or error states */}
        {isLoading && <p className="text-gray-600">Loading audit log data...</p>}
        {error && <p className="text-red-600">Error loading audit log data. Please try again later.</p>}
        
        <div className="bg-white rounded-md shadow-md border border-gray-200 p-2">
          <div className="flex justify-end mb-2">
            {auditLogs && auditLogs.length > 0 && (
              <ExportButton
                exportData={auditLogs}
                fields={[
                  'endpoint_execution_datetime',
                  'user_name',
                  'operation',
                  'page_name',
                  'attribute_key',
                  'value_before_change',
                  'value_after_change',
                  'client_name',
                  'fund_name',
                  'key_path'
                ]}
                fileName={`audit_log_${dayjs(startDate).format('YYYY-MM-DD')}_to_${dayjs(endDate).format('YYYY-MM-DD')}`}
                allowPdfExport={true}
                pdfIdentifier={`audit_log_table_${dayjs(startDate).format('YYYY-MM-DD')}_to_${dayjs(endDate).format('YYYY-MM-DD')}`}
              />
            )}
          </div>
          <div className="overflow-x-auto border border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Timestamp</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">User</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Operation</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Page</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Attribute</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Before</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">After</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {auditLogs && auditLogs.length > 0 ? (
                  auditLogs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((log: AuditLogEntry, index: number) => (
                      <tr key={index} className="hover:bg-blue-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {new Date(log.endpoint_execution_datetime).toISOString().split('T')[0]} {new Date(log.endpoint_execution_datetime).toTimeString().split(' ')[0]}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{log.user_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                            log.operation === 'create' ? 'bg-green-100 text-green-800' :
                            log.operation === 'update' ? 'bg-blue-100 text-blue-800' :
                            log.operation === 'delete' ? 'bg-red-100 text-red-800' :
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {log.operation}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{log.page_name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{log.attribute_key}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          <div 
                            className="max-w-xs truncate cursor-pointer hover:text-mainblue hover:underline" 
                            title="Click to view full value"
                            onClick={() => openValueModal("Before Change", log.value_before_change)}
                          >
                            {log.value_before_change}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          <div 
                            className="max-w-xs truncate cursor-pointer hover:text-mainblue hover:underline" 
                            title="Click to view full value"
                            onClick={() => openValueModal("After Change", log.value_after_change)}
                          >
                            {log.value_after_change}
                          </div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={7} className="px-6 py-4 text-center text-sm text-gray-500">
                      {isLoading ? 'Loading data...' : 'No audit log entries found for the selected date range.'}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {auditLogs && auditLogs.length > 0 && (
            <div className="bg-gray-50 px-4 py-3 flex items-center justify-between border border-gray-200 sm:px-6 rounded-b-md">
              <div className="flex-1 flex justify-between sm:hidden">
                <button
                  onClick={() => handleChangePage(page - 1)}
                  disabled={page === 0}
                  className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${page === 0 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-blue-50'}`}
                >
                  Previous
                </button>
                <button
                  onClick={() => handleChangePage(page + 1)}
                  disabled={page >= totalPages - 1}
                  className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md ${page >= totalPages - 1 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-white text-gray-700 hover:bg-blue-50'}`}
                >
                  Next
                </button>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-600">
                    Showing <span className="font-medium">{page * rowsPerPage + 1}</span> to{' '}
                    <span className="font-medium">
                      {Math.min((page + 1) * rowsPerPage, auditLogs.length)}
                    </span>{' '}
                    of <span className="font-medium">{auditLogs.length}</span> results
                  </p>
                </div>
                <div className="flex items-center">
                  <div className="mr-6 relative">
                    <label htmlFor="rowsPerPage" className="mr-2 text-sm text-gray-600">Rows per page:</label>
                    <div className="inline-block relative">
                      <select
                        id="rowsPerPage"
                        value={rowsPerPage}
                        onChange={handleChangeRowsPerPage}
                        className="border border-gray-300 rounded px-3 py-1 pr-10 text-sm bg-white appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        {[10, 25, 50].map(option => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500">
                        <FiChevronDown size={16} />
                      </div>
                    </div>
                  </div>
                  <div className="mr-4 flex items-center">
                    <label htmlFor="pageInput" className="mr-2 text-sm text-gray-600">Go to page:</label>
                    <input
                      id="pageInput"
                      type="text"
                      value={pageInputValue}
                      onChange={handlePageInputChange}
                      onKeyDown={handlePageInputKeyDown}
                      className="border border-gray-300 rounded w-16 px-2 py-1 text-sm bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder={`1-${totalPages}`}
                    />
                  </div>
                  <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                      onClick={() => handleChangePage(page - 1)}
                      disabled={page === 0}
                      className={`relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium ${page === 0 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-blue-50'}`}
                    >
                      <span className="sr-only">Previous</span>
                      &larr;
                    </button>
                    {(() => {
                      // Calculate the range of page numbers to display
                      const displayPages = 5; // Always show 5 page buttons
                      let startPage = Math.max(0, Math.min(page - Math.floor(displayPages / 2), totalPages - displayPages));
                      
                      // If we have fewer pages than display count, adjust accordingly
                      if (totalPages <= displayPages) {
                        startPage = 0;
                      }
                      
                      return Array.from({ length: Math.min(displayPages, totalPages) }, (_, i) => {
                        const pageNum = startPage + i;
                        return (
                          <button
                            key={pageNum}
                            onClick={() => handleChangePage(pageNum)}
                            className={`w-10 h-8 flex items-center justify-center border ${
                              page === pageNum 
                                ? 'bg-blue-100 border-blue-500 text-blue-600 z-10' 
                                : 'bg-white border-gray-300 text-gray-500 hover:bg-blue-50'
                            } text-sm font-medium`}
                          >
                            {pageNum + 1}
                          </button>
                        );
                      });
                    })()}
                    <button
                      onClick={() => handleChangePage(page + 1)}
                      disabled={page >= totalPages - 1}
                      className={`relative inline-flex items-center px-2 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium ${page >= totalPages - 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:bg-blue-50'}`}
                    >
                      <span className="sr-only">Next</span>
                      &rarr;
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Add the modal component */}
      <ValueModal 
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={modalTitle}
        value={modalValue}
      />
    </GeneralComponentErrorShield>
  );
};

export default AuditLog;
