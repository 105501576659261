import { CheckCircle } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import hotToast, { Toaster as HotToaster } from 'react-hot-toast';
import { IconButton } from '@mui/material';
import { cn } from '../../utilities/cn';

export const Toaster = HotToaster;

interface ToastProps extends React.HTMLAttributes<HTMLDivElement> {
  visible: boolean;
  title: string;
  message?: string;
  icon?: React.ReactNode;
  closeToast: () => void;
}

interface ToastOpts {
  title: string;
  message?: string;
  type?: 'success' | 'error' | 'warning' | 'default';
  duration?: number;
  className?: string;
}

export function Toast({
  visible,
  icon,
  className,
  title,
  message,
  closeToast,
  ...props
}: ToastProps) {
  return (
    <div
      className={cn(
        'flex w-fit max-w-[32rem] items-center justify-center gap-2 rounded-lg bg-white p-2 shadow-md',
        className,
      )}
      style={{
        visibility: visible ? 'visible' : 'hidden',
      }}
      {...props}
    >
      {icon}
      <div>
        <p className="ml-2 font-bold">{title}</p>
        <p className="ml-2 text-left text-sm">{message}</p>
      </div>
      <IconButton onClick={closeToast} className="p-2">
        <span className="sr-only">Close</span>
        <DisabledByDefaultIcon className="text-lg text-black" />
      </IconButton>
    </div>
  );
}

export function toast(opts: ToastOpts) {
  const { title, message, type = 'default', duration = 3000, className } = opts;

  return hotToast.custom(
    (t) => (
      <Toast
        title={title}
        message={message}
        visible={t.visible}
        className={className}
        icon={
          type === 'success' ? (
            <CheckCircle />
          ) : type === 'error' ? (
            <ErrorIcon />
          ) : type === 'warning' ? (
            <WarningIcon />
          ) : null
        }
        style={{
          border:
            type === 'success'
              ? '2px solid #10B981'
              : type === 'error'
                ? '2px solid #EF4444'
                : type === 'warning'
                  ? '2px solid #F59E0B'
                  : '2px solid #9CA3AF',
          color:
            type === 'success'
              ? '#10B981'
              : type === 'error'
                ? '#EF4444'
                : type === 'warning'
                  ? '#F59E0B'
                  : '#9CA3AF',
        }}
        closeToast={() => {
          hotToast.remove(t.id);
        }}
      ></Toast>
    ),
    { duration },
  );
}
