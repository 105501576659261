import { FunctionComponent } from 'react';
import { cn } from '../../../../utilities/cn';

interface LegendProps extends React.HTMLAttributes<HTMLDivElement> {
  values: string[];
}

export const Legend: FunctionComponent<LegendProps> = ({
  values,
  className,
}) => {
  return (
    <ul
      className={cn(
        'flex list-none flex-col gap-0 px-2 text-sm sm:flex-row md:gap-3',
        className,
      )}
    >
      {values.map((value, index) => (
        <li key={index} className="flex items-center">
          <span
            className={`mr-2 inline-block h-2.5 w-2.5 ${
              index === 0
                ? 'bg-[var(--pastle-blue-light)]' // Editable cells
                : index === 1
                  ? 'bg-[var(--pastle-green)]' // Local edits (unsubmitted)
                  : index === 2
                    ? 'bg-[var(--pastle-red)]' // Update request failed
                    : index === 3
                      ? 'bg-[var(--pastle-orange)]' // Update Pending (New)
                      : 'bg-black'
            }`}
          ></span>
          {value}
        </li>
      ))}
    </ul>
  );
};
