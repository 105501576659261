import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userIdSelector } from '../../../../../redux/auth/selectors';
import DeleteUserConfirmation from './DeleteUserConfirmation.component';
import UserRoleConfirmation from './UserRoleConfirmation.component';
import { roleTranslator } from './../ManageAccounts.component';
import dayjs from 'dayjs';
import TokenService from '../../../../../services/token-service';
import FastAPIInstance from '../../../../../services/risksystem3-public-api';
import { useQuery } from '@tanstack/react-query';

// [2024-08-27, Tom Walsh] - Temporary code to remove '_funds' accounts from the list after corresponding deactivation date
// Deactivation dates for accounts, deafault is end of october 2024
export const AccountDeactivationDates: { [index: string]: string } = {
  pure_funds: '2024-10-31',
  gemini_funds: '2024-10-31',
  epic_funds: '2024-10-31',
  gem_funds: '2024-10-31',
  snif_funds: '2024-10-31',
  trium_funds: '2024-10-31',
  quayside_funds: '2024-10-31',
  greshamhouse_funds: '2024-10-31',
  corum_butler_funds: '2024-10-31',
  bflexion_funds: '2024-10-31',
  wcapital_funds: '2024-10-31',
  coutts_uk_funds: '2024-12-10', // According to Jonathan, this account is just used internally for producing reports on the portal. We should make these reports available on internal frontend and then delete this account
  coutts_ie_funds: '2024-12-10', // According to Jonathan, this account is just used internally for producing reports on the portal. We should make these reports available on internal frontend and then delete this account
  adepa_funds: '2025-02-28',
  coutts_funds: '2025-02-28',
  coutts_product_team: '2025-02-28',
};
// [2024-08-27, Tom Walsh] - End of temporary code

const UserList: FC = () => {
  const userName = useSelector(userIdSelector);
  const userDetails = TokenService.getUser();

  // Use React Query to fetch users
  const { data: users = [], refetch } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await FastAPIInstance.get(
        `/v1/external_user_management/user_list/client_id/${userDetails.user_client_id}`
      );
      
      const ordered = response.data.reduce((a: any, c: any) => {
        if (c.user_name === userName) a.unshift(c);
        else a.push(c);
        return a;
      }, []);
      
      // Filter out deactivated accounts
      const currentDate = dayjs();
      return ordered.filter((user: any) => {
        if (
          user.user_name in AccountDeactivationDates &&
          dayjs(AccountDeactivationDates[user.user_name]) <= currentDate
        ) {
          return false;
        }
        return true;
      });
    }
  });

  const [numberOfAccounts, setNumberOfAccounts] = React.useState<{
    current_number_of_users: number;
    maximum_allowed_users: number;
  } | null>(null);

  // Then use useEffect to fetch account limits when users data changes
  React.useEffect(() => {
    if (users.length > 0) {
      // Get account limits after users are loaded
      FastAPIInstance.get(
        `/v1/external_user_management/max_create_account_status/client_id/${userDetails.user_client_id}`
      ).then((response) => {
        // Adjust counts for deactivated accounts
        const currentDate = dayjs();
        const numDeactivated = Object.entries(AccountDeactivationDates)
          .filter(([username, deactivationDate]) => 
            users.some((user: any) => user.user_name === username) &&
            dayjs(deactivationDate) <= currentDate
          ).length;

        setNumberOfAccounts({
          current_number_of_users: response.data.current_number_of_users - numDeactivated,
          maximum_allowed_users: response.data.maximum_allowed_users - numDeactivated
        });
      });
    }
  }, [users, userDetails.user_client_id]);

  return (
    <div className="w-full">
      <div className="mb-2">
        <div className="inline-block bg-white rounded-md border border-gray-300 px-4 py-1">
          <span className="font-medium">Accounts:</span>{' '}
          {numberOfAccounts ? (
            <span>
              {numberOfAccounts.current_number_of_users} / {numberOfAccounts.maximum_allowed_users}
            </span>
          ) : (
            'Loading...'
          )}
        </div>
      </div>
      
      <div className="bg-white rounded-md shadow-sm border border-gray-200 overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="w-16 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User ID</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Expiry Date</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users.map((user: any, index: number) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center justify-center">
                    <div className="h-10 w-10 rounded-full flex items-center justify-center bg-gray-100 border border-mainblue text-mainblue font-bold">
                      {user.user_name.charAt(0).toUpperCase()}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <div className="text-sm font-medium text-gray-900">{user.user_name}</div>
                    {user.user_name === userName && (
                      <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        It's You
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.user_email}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.user_expiry_date}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{roleTranslator(user.user_type)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <div className="flex space-x-2">
                    {user.user_name !== userName && user.user_type !== 'external_administrator' && (
                      <UserRoleConfirmation user={user} reloadUsers={refetch} />
                    )}
                    {user.user_name !== userName && (
                      <DeleteUserConfirmation user={user} reloadUsers={refetch} />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserList;
