import React from 'react';
import { SettingsComponentProps } from '../../SettingsRouter.component';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import NewUser from './subComponents/NewUser.component';
import UserList from './subComponents/UserList.component';

export const roleTranslator = (role: string): string | null => {
  switch (role) {
    case 'external_administrator':
      return 'Administrator';
    case 'external_individual':
      return 'Individual';
    default:
      return null;
  }
};

export const roleTranslatorReverse = (role: string): string | null => {
  switch (role) {
    case 'Administrator':
      return 'external_administrator';
    case 'Individual':
      return 'external_individual';
    default:
      return null;
  }
};

const ManageAccounts: React.FC<SettingsComponentProps> = () => {
  return (
    <GeneralComponentErrorShield
      dataObjects={[]}
      customLoadingMessages={[
        'Loading Account Data...',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <div className="w-full flex flex-col p-1.5 gap-2">
        <div className="bg-white rounded-md shadow-md border border-gray-200 p-4">
          <h1 className="text-xl font-bold mb-2 text-mainblue underline">
            Manage Accounts
          </h1>
          <p className="text-base text-mainblue mb-4">
            Create and manage user accounts for the system.
          </p>
          
          {/* New User Form */}
          <div className="bg-mainblue bg-opacity-10 rounded-md border border-mainblue border-opacity-20 p-1">
            <NewUser />
          </div>
        </div>
        
        {/* User List */}
        <div className="bg-white rounded-md shadow-md border border-gray-200 p-2">
          <div>
            <UserList />
          </div>
        </div>
      </div>
    </GeneralComponentErrorShield>
  );
};

export default ManageAccounts;
