import {
  LoaderFunctionArgs,
  Navigate,
  RouteObject,
  createBrowserRouter,
  useNavigate,
  useParams,
} from 'react-router-dom';
import LoggedOutRoute from './LoggedOutRoute';
import LoginPage from '../components/pages/auth/LoginPage';
import SignupPage from '../components/pages/auth/SignupPage';
import ResetPassword from '../components/pages/auth/ResetPassword.component';
import DocumentationViewer from '../components/documentation/DocumentationViewer.component';
import MyAccount from '../components/settings/pages/myAccount/MyAccount.component';
import SecuritySettings from '../components/settings/pages/securitySettings/SecuritySettings.component';
import PageNotFound from '../components/settings/pages/PageNotFound.component';
import SettingsLayout from '../components/layout/layouts/SettingsLayout.component';
import DocumentationLayout from '../components/layout/layouts/DocumentationLayout.component';
import Dashboard from '../components/pages/dashboard/Dashboard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientSectionsSelector } from '../redux/pages/selectors';
import axios from 'axios';
import { fetchClientConfig } from '../redux/pages/actions';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../redux/auth/selectors';
import ManageAccounts from '../components/settings/pages/manageAccounts/ManageAccounts.component';
import GeneralLayout from '../components/layout/layouts/GeneralLayout.component';
import AuthLayout from '../components/layout/layouts/AuthLayout';
import { RaidrRoutes } from './Raidr.routes';
import ProtectedRoutes from './ProtectedRoutes';
import { OverviewsRoutes } from './Overviews.routes';
import { CombinedRoutes } from './CombinedRoutes.routes';
import FundRoutes from './Funds.routes';
import { GeneralPageWrapper } from '../components/layout/general/GeneralPageWrapper';
import { GeneralFundInfoWrapper } from '../components/layout/general/GeneralFundInfoWrapper';
import { RootRoutePaths } from './page-routes';
import AuditLog from '../components/settings/pages/auditLog/AuditLog.component';

export const checkFundIdLoader = async (
  data: LoaderFunctionArgs,
  pageId: string,
  Component: any,
) => {
  const url = new URL(data.request.url); // Get the full URL of the request
  const fundId = url.searchParams.get('fundId'); // Check for the 'fundId' query parameter

  if (fundId) {
    return (
      <GeneralPageWrapper pageId={pageId}>
        <GeneralFundInfoWrapper
          Component={Component}
          pageId={pageId}
          fundId={fundId}
        />
      </GeneralPageWrapper>
    );
  }

  return null; // Pass the fundId to the route element via loader data
};

// Logic from the original file into a component - it deals with the fact there are no sections and that clicking the nav should open the first page that of that section
export const UnconventionalRedirect = ({
  hardSection,
}: {
  hardSection?: string;
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isLoggedInSelector);

  const { section } = useParams(); // Get the section ID from the URL
  const navigate = useNavigate();
  const configName = useSelector(configNameSelector);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  useEffect(() => {
    if (isAuthenticated && configName) {
      dispatch(fetchClientConfig(configName, source));
    }
  }, [isAuthenticated]);

  const usedSection = hardSection || section;

  const clientSections = useSelector(clientSectionsSelector);

  useEffect(() => {
    if (!usedSection || !clientSections) return;

    // Find the matching section
    const matchingSection = clientSections.find(
      (s: any) => s.sectionId === usedSection,
    );

    if (matchingSection && matchingSection.pages?.length > 0) {
      // Redirect to the first page within the section
      navigate(`/${matchingSection.sectionId}/${matchingSection.pages[0]}`, {
        replace: true,
      });
    }
  }, [usedSection, clientSections, navigate]);

  // Optionally, render a loading or fallback UI during redirection
  return <PageNotFound />;
};

// Data can eventually be the context of the user - however when using the TokenService we can call this from anywhere in the app howwever passing a prop is useful for application data being passed to loader/action functions
export function makeRoutes(data: any): RouteObject[] {
  return [
    {
      element: <ProtectedRoutes />,
      children: [
        {
          element: <GeneralLayout />,
          children: [
            {
              path: '/',
              index: true,
              element: <Navigate to={RootRoutePaths.DASHBOARD} replace />,
            },
            {
              id: 'dashboard',
              path: RootRoutePaths.DASHBOARD,
              element: <Dashboard />,
            },
            {
              path: RootRoutePaths.DYNAMIC_SECTION,
              element: <UnconventionalRedirect />,
            },

            // This is showing how we can modularize the routes and have a single file for all the overviews if Raptor gets too big

            ...OverviewsRoutes(data), // These are the routes that are general overview pages
            ...FundRoutes(data), // These are the fund specific routes
            ...CombinedRoutes(data), // These are routes that share the same url as fund general and general overview

            {
              path: '*',
              element: <PageNotFound />,
            },
          ],
        },

        ...RaidrRoutes(data),
        {
          path: RootRoutePaths.SETTINGS,
          element: <SettingsLayout />,
          children: [
            {
              path: RootRoutePaths.MY_ACCOUNT,
              element: <MyAccount />,
            },
            {
              path: RootRoutePaths.MANAGE_ACCOUNTS,
              element: <ManageAccounts />,
            },
            {
              path: RootRoutePaths.SECURITY_SETTINGS,
              element: <SecuritySettings />,
            },
            {
              path: RootRoutePaths.AUDIT_LOG,
              element: <AuditLog />,
            },
            {
              path: '*',
              element: <PageNotFound />,
            },
          ],
        },
        {
          path: RootRoutePaths.DOCUMENTATION,
          element: <DocumentationLayout />,
          children: [
            {
              path: '',
              index: true,
              element: <DocumentationViewer />,
            },
          ],
        },
      ],
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          path: RootRoutePaths.LOGIN,
          element: <LoggedOutRoute Component={LoginPage} />,
        },
        {
          path: RootRoutePaths.SIGNUP,
          element: <LoggedOutRoute Component={SignupPage} />,
        },
        {
          path: RootRoutePaths.RESET_PASSWORD,
          element: <LoggedOutRoute Component={ResetPassword} />,
        },
      ],
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ];
}

export const router = (data: any) =>
  createBrowserRouter(makeRoutes(data), { basename: '/raptor' });

export const All_RAPTOR_ROUTES = (): string[] => {
  const paths: string[] = [];

  const traverseRoutes = (routeList: RouteObject[]): void => {
    routeList.forEach((route) => {
      if (route.path) {
        // Extract the last part of the path
        const segments = route.path.split('/');
        const lastPart = segments[segments.length - 1];

        // Exclude dynamic parts (starting with ":") and "*"
        if (lastPart && lastPart !== '*' && !lastPart.startsWith(':')) {
          paths.push(lastPart);
        }
      }
      if (route.children) {
        traverseRoutes(route.children);
      }
    });
  };

  traverseRoutes(makeRoutes(''));
  return paths;
};
