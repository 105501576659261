import { Tooltip } from '@mui/material';
import {
  FunctionComponent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { GeneralFunctionType } from '../types/functions.types';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { formatInitialValue, isCellEditable } from '../utils/ultratable.utils';
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from '../../../toast/Toast';
import SwitchComponent from './SwitchComponent';
import { useEditableCellStyles } from '../styles/extras.styles';
import { NumberEditInput } from './NumberEditInput';
import {
  conditionallyUpdateCellMeta,
  conditionallyUpdateCellMetaWithValue,
  isCellValueEqual,
} from '../utils/validation';
import { EditableFieldsProperties } from '../types/editable.types';
import { EditableDropdown } from './EditableDropdown';
import {
  isLocalEditType,
  isLocalEditTypeAndValueIsEmpty,
} from './FilterColumn';
import { NESTED_KEY_REGEX } from '../../../pages/manco/positionUpdatePortal/PositionUpdatePortal.page';
import LoadingSpinner from '../../../ui/LoadingSpinner';

interface EditableCellProps {
  getValue: () => any;
  original: any;
  index: number;
  column: any;
  table: any;
  cell: any;
  setUpdateLog?: (log: any) => void;
  fn?: GeneralFunctionType;
  fnIsPending?: boolean;
}

export const EditableCellWrapper: React.FC<EditableCellProps> = (props) => {
  const methods = useForm({
    // defaultValues: {
    //   [props.column.id]: props.getValue(),
    // }, TODO: Figure out default values and which is correct key_name and prop
    mode: 'onChange',
  });

  return (
    <FormProvider {...methods}>
      <EditableCell {...props} />
    </FormProvider>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  getValue,
  original,
  index,
  column,
  table,
  setUpdateLog,
  fn,
  fnIsPending,
}) => {
  const classes = useEditableCellStyles();
  const [cellError, setCellError] = useState(false);
  const initialValue = getValue();
  const [value, setValue] = useState(formatInitialValue(initialValue));
  const [isUpdated, setIsUpdated] = useState(false);
  const [responseError, setResponseError] = useState(false);

  const { trigger, clearErrors } = useFormContext();

  const buttonDisabled =
    cellError || isLocalEditTypeAndValueIsEmpty(initialValue);

  const isEditable = isCellEditable(original, column);

  const testingFn = (targetValue: any) => {
    conditionallyUpdateCellMetaWithValue(
      targetValue,
      getValue(),
      index,
      column.id,
      table,
      setIsUpdated,
    );
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    conditionallyUpdateCellMeta(
      e,
      getValue(),
      index,
      column.id,
      table,
      setIsUpdated,
    );
  };

  // Check if the value has been updated - timeout is used to account for re-rendering delays
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const valuesEqual = isCellValueEqual(initialValue, value);
      setIsUpdated(!valuesEqual);
    }, 100);

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, [initialValue, value]);

  useEffect(() => {
    setValue(formatInitialValue(initialValue));
  }, [initialValue]);

  if (fnIsPending) {
    return <LoadingSpinner />;
  }

  const keyName = original.editable_fields.find(
    (field: any) => field.key_name === column.id,
  )!;

  async function handleSubmitEdits() {
    if (fn) {
      try {
        const response = await fn(original, column.id, value);
        table.options.meta?.updateData(index, column.id, value, true);

        toast({
          title: 'Position Field Updated',
          message: `It will only affect calculations in the next run of the fund, unless you request a recalculation for this run.`,
          type: 'warning',
          className: 'text-xl',
          duration: 7500,
        });
        setIsUpdated(false);
      } catch (error) {
        toast({
          title: 'Error Updating Field',
          message: `${error}`,
          type: 'error',
        });
        setResponseError(true);
      }
    }
  }

  async function handleCancelEdits() {
    // NO idea why await works here to render the validation of trigger function to UI

    // Update the tanstack table state
    table.options.meta?.updateData(
      index,
      column.id,
      formatInitialValue(initialValue, true),
      true,
    );

    // Set the form value for the table cell - Await somehow works and delays the trigger
    await setValue(formatInitialValue(initialValue, true));

    // Trigger new validation for cell

    // trigger(keyName?.key_name);

    // Clear the error for the cell
    clearErrors(keyName?.key_name);
  }

  return isEditable ? (
    <div className={classes.container}>
      <EditableInput
        editOptions={
          original.editable_fields.find(
            (field: any) => field.key_name === column.id,
          )!
        }
        initialValue={initialValue}
        value={value}
        setValue={setValue}
        onBlur={onBlur}
        // onChange={onChange}
        original={original}
        columnID={column.id}
        fn={fn}
        updated={isUpdated}
        responseError={responseError}
        setResponseError={setResponseError}
        setCellError={setCellError}
        handleSubmitEdits={handleSubmitEdits}
        handleCancelEdits={handleCancelEdits}
        testFn={testingFn}
      />
      {isUpdated && (
        <div className={classes.actionBtnContainer}>
          <button
            className={clsx(classes.actionBtn, classes.confirmBtn, {
              [classes.disabled]: buttonDisabled,
            })}
            disabled={buttonDisabled}
            onMouseDown={handleSubmitEdits}
          >
            <CheckCircleOutlineIcon style={{ fill: 'green' }} />
          </button>

          <button
            className={clsx(classes.actionBtn, classes.cancelBtn)}
            onMouseDown={handleCancelEdits}
          >
            <CancelIcon style={{ fill: 'red' }} />
          </button>
        </div>
      )}
    </div>
  ) : (
    <>{value as string}</>
  );
};

export interface EditableInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  value: any;
  initialValue: any;
  setValue: React.Dispatch<any>;
  editOptions: EditableFieldsProperties;
  original: any;
  columnID: string;
  fn?: GeneralFunctionType;
  updated: boolean;
  responseError?: boolean;
  setResponseError: React.Dispatch<React.SetStateAction<boolean>>;
  setCellError: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitEdits: () => void;
  handleCancelEdits: () => void;
  testFn: (targetValue: any) => void;
}

const EditableInput: FunctionComponent<EditableInputProps> = (props) => {
  const classes = useEditableCellStyles();

  const {
    register,
    formState: { errors },
    trigger,
    setValue: setFormValue, // Import setValue from useFormContext
  } = useFormContext<any>();

  const { raptor_type: type, from_selection, validation } = props.editOptions;

  const registerId = props.editOptions.key_name;
  const hasError = (errors && errors[registerId]) || props.responseError;

  useEffect(() => {
    props.setCellError(!!hasError);
  }, [hasError]);

  useEffect(() => {
    setFormValue(registerId, props.value);

    // Trigger validation for only local edits cells
    if (isLocalEditType(props.initialValue)) {
      trigger(registerId);
    }
  }, [props.value, registerId, setFormValue, trigger, props.initialValue]);

  if (from_selection && from_selection.length == 2) {
    const truthy = props.original[props.columnID] === 'yes';

    return (
      <SwitchComponent
        {...props}
        classes={classes}
        columnID={props.columnID}
        checked={truthy}
        fn={props.fn}
      />
    );
  }

  const nestedKeyID = props.columnID.replace(NESTED_KEY_REGEX, '.');
  const showChange = props.original?.cdum_changed_paths?.some(
    (path: any) =>
      path.trim().toLowerCase() === nestedKeyID.trim().toLowerCase(),
  );

  return (
    <div className={classes.inputContainer}>
      {type === 'string' && validation?.values.length === 0 ? (
        <input
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              props.handleSubmitEdits();
            }
            if (e.key === 'Escape') {
              props.handleCancelEdits();
            }
          }}
          onBlur={props.onBlur}
          value={props.value}
          className={clsx(classes.editableCell, {
            [classes.error]: props.responseError, // Highest priority
            [classes.updated]: !props.responseError && props.updated, // Takes precedence over "changed"
            [classes.changed]:
              !props.responseError && !props.updated && showChange, // Only applies if "updated" is false
          })}
          onChange={(e) => {
            props.setResponseError(false);
            props.setValue(e.target.value);
            if (props.onChange) {
              props.onChange(e);
            }
          }}
        />
      ) : type === 'string' ? (
        <EditableDropdown
          onBlur={props.onBlur}
          validation={validation}
          props={props}
          setResponseError={props.setResponseError}
          responseError={props.responseError}
          handleCancelEdits={props.handleCancelEdits}
          handleSubmitEdits={props.handleSubmitEdits}
          showChange={showChange}
        />
      ) : (
        <NumberEditInput
          onBlur={props.onBlur}
          validation={validation}
          props={props}
          errors={errors}
          register={register}
          setResponseError={props.setResponseError}
          responseError={props.responseError}
          handleCancelEdits={props.handleCancelEdits}
          handleSubmitEdits={props.handleSubmitEdits}
          testFn={props.testFn}
          showChange={showChange}
        />
      )}
      {errors?.[registerId]?.message && (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={errors?.[registerId]?.message?.toString() || ''}
          open={true}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: -10,
            }}
          />
        </Tooltip>
      )}
    </div>
  );
};
