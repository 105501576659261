// PortalFetchWrapper is a wrapper component that fetches authorized funds and passes them to the wrapped component.
// It uses react-query for data fetching and handles loading and error states.

import { ReactElement } from 'react-markdown/lib/react-markdown';
import RaptorLoading from '../../../feedback/RaptorLoading';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { applyFundFilter } from '../../../../redux/pages/actions';
import { usePositionPortalConfigs } from './services/queries';

interface PortalFetchWrapperProps {
  component: ReactElement;
}

export const PortalFetchWrapper = <T extends object>(
  props: PortalFetchWrapperProps & T,
) => {
  const dispatch = useDispatch();
  const { component } = props;
  const [isReady, setIsReady] = useState(false); // Track readiness state

  const { data, isPending, error } = usePositionPortalConfigs();

  // If we use the useFilterFunds we get invalid number of hooks error
  useEffect(() => {
    if (data?.authorised_funds && !data.authorise_all_funds) {
      const authorisedFunds =
        data.authorised_funds.length === 0
          ? ['no-funds']
          : data.authorised_funds;

      dispatch(applyFundFilter(authorisedFunds, '', ''));
      setIsReady(true); // Mark ready after dispatch
    } else {
      setIsReady(true); // Mark ready after dispatch
    }

    return () => {
      dispatch(applyFundFilter([], '', ''));
    };
  }, [data, dispatch]);

  if (isPending || !isReady) {
    return (
      <RaptorLoading
        centerWrap
        messages={[
          'Loading Potential Portal Funds',
          'This may take a few seconds...',
        ]}
      />
    );
  }

  if (error) {
    if (error.response?.status === 401) {
      return (
        <NoDataMessage
          message={
            'You are not authorised to access this module. Please contact support if you wish to gain portal access.'
          }
        />
      );
    }

    if (error.response?.status === 404) {
      return (
        <NoDataMessage
          message={
            'Fund is currently processing. Position Data can be updated after the fund has finished processing. Please visit the "Report Delivery Oversight" page to confirm. If the fund is finished processing and this issue persists, please contact support.'
          }
        />
      );
    }

    return <NoDataMessage message={error?.message} />;
  }

  if (!data?.authorised_funds) {
    return <NoDataMessage message={'No Authorised Funds Available'} />;
  }

  const authConfig = data;

  // Pass authFunds along with other props to the component
  return <>{React.cloneElement(component, { authConfig, ...props })}</>;
};
