// Desc: Loading spinner component

import { FunctionComponent } from 'react';
import { cn } from '../../utilities/cn';

const LoadingSpinner: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ className }) => {
  return (
    <div
      className={cn(
        'box-border inline-block h-9 w-9 animate-spin cursor-pointer rounded-full border-[5px] border-white border-b-[var(--mainBlue)] font-sans text-[#ccc]',
        className,
      )}
    />
  );
};

export default LoadingSpinner;
