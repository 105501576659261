import React, { FC, FormEvent, useState, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { FiChevronDown, FiSend, FiLoader } from 'react-icons/fi';
import { roleTranslatorReverse } from '../ManageAccounts.component';
import TokenService from '../../../../../services/token-service';
import FastAPIInstance from '../../../../../services/risksystem3-public-api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

const roleOptions = [
  'Administrator',
  'Individual',
];

const NewUser: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [role, setRole] = useState<string>('Individual');
  const queryClient = useQueryClient();

  // Create a mutation function using React Query
  const sendInviteMutation = useMutation({
    mutationFn: async () => {
      if (!email || email.length === 0) {
        throw new Error('Please enter an email address.');
      }
      
      return FastAPIInstance.post(
        '/v1/external_user_management/create_user_invitation',
        {
          user_email: email,
          user_type: roleTranslatorReverse(role),
        },
        {
          headers: {
            Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
          },
        }
      );
    },
    onSuccess: () => {
      // Clear the form
      setEmail('');
      
      // Invalidate the users query to refresh the list
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  });

  // Helper function to extract error message from API response
  const getErrorMessage = (error: unknown): string => {
    if (axios.isAxiosError(error) && error.response?.data) {
      // Try to get the error detail from the response
      if (error.response.data.detail) {
        return error.response.data.detail;
      }
      
      // If there's no detail field but there's some other message
      if (typeof error.response.data === 'string') {
        return error.response.data;
      }
    }
    
    // If it's a regular Error object
    if (error instanceof Error) {
      return error.message;
    }
    
    // Fallback error message
    return 'An error occurred. Please try again.';
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    sendInviteMutation.mutate();
  };

  return (
    <div className="w-full flex flex-col bg-white p-2.5 rounded">
      <div className="text-xl border-b border-mainblue w-fit text-mainblue font-semibold">
        Invite New User
      </div>
      <div className="text-xs font-normal mt-1 text-mainblue">
        An email will be sent to the new user with a link to create an account.
      </div>
      <div className="text-xs font-normal text-red-600">
        This new user will be given access to all features and funds. If you
        would like to create an account with restricted access, please contact
        RiskSystem support.
      </div>
      <form onSubmit={handleSubmit} autoComplete="off">
        <input
          className="w-72 h-7.5 py-1 px-2.5 border border-mainblue text-sm font-normal my-2.5 rounded focus:outline-none focus:ring-2 focus:ring-mainblue"
          type="email"
          id={`email-input`}
          name="email-input"
          placeholder={'Email Address'}
          autoComplete="new-email"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          disabled={sendInviteMutation.isPending}
        />
        
        {/* Simplified Headless UI Listbox (Dropdown) with more minimalist selected state */}
        <div className="w-72 mb-2.5">
          <Listbox 
            value={role} 
            onChange={setRole}
            disabled={sendInviteMutation.isPending}
          >
            <div className="relative">
              <Listbox.Button className={`relative w-full h-7.5 py-1 px-2.5 text-left bg-white border border-mainblue rounded text-sm font-normal cursor-pointer focus:outline-none focus:ring-2 focus:ring-mainblue ${sendInviteMutation.isPending ? 'opacity-70 cursor-not-allowed' : ''}`}>
                <span className="block truncate">{role}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <FiChevronDown className="w-4 h-4 text-mainblue" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute w-full mt-1 overflow-auto text-sm bg-white rounded-md shadow-md focus:outline-none z-10 border border-gray-200">
                  {roleOptions.map((option, optionIdx) => (
                    <Listbox.Option
                      key={optionIdx}
                      className={({ active, selected }) => {
                        return `
                          ${active ? 'bg-blue-50' : ''}
                          ${selected ? 'text-mainblue font-medium' : 'text-gray-900'}
                          cursor-pointer select-none py-2 px-4
                        `;
                      }}
                      value={option}
                    >
                      {option}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        
        <div className="flex flex-row items-center gap-3">
          <button 
            type="submit" 
            className={`flex items-center justify-center gap-2 py-2 px-4 rounded text-sm font-medium bg-mainblue text-white transition-all ${
              sendInviteMutation.isPending 
                ? 'opacity-70 cursor-not-allowed' 
                : 'hover:bg-blue-600 active:bg-blue-700'
            }`}
            disabled={sendInviteMutation.isPending}
          >
            {sendInviteMutation.isPending ? (
              <>
                <FiLoader className="w-4 h-4 animate-spin" />
                Sending...
              </>
            ) : (
              <>
                <FiSend className="w-4 h-4" />
                Send Invite
              </>
            )}
          </button>
          
          {/* Status messages */}
          {sendInviteMutation.isSuccess && (
            <span className="text-green-600 text-sm">
              Signup invitation sent successfully!
            </span>
          )}
          
          {sendInviteMutation.isError && (
            <span className="text-red-600 text-sm">
              {getErrorMessage(sendInviteMutation.error)}
            </span>
          )}
        </div>
      </form>
    </div>
  );
};

export default NewUser;
