import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Raptor2Loading from '../../../../feedback/Raptor2Loading';

import { useFactSheetDemoPDF } from '../services/queries';
import BackToOverviewBtn from '../../components/buttons/BackToOverviewBtn';
import { RaidrRoutePaths } from '../../../../../routes/page-routes';
import { useNavigate } from 'react-router-dom';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import { useDispatch } from 'react-redux';
import { getOS } from '../../../../../utilities/helpers/common/system';
import { Button, Tooltip } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ErrorBoundaryMessage from '../../../../ui/ErrorBoundaryMessage';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

// TODO: Think of a global handler for PDF zooming etc - handled by trigger similar to redux
// Reduce the functional bloating of component to abstract out to utils

const FactSheetViewer: FunctionComponent = () => {
  const pdfViewerRef = useRef<HTMLDivElement | null>(null);
  const scrollPosRef = useRef<number>(0);

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [numPages, setNumPages] = useState<number>(0);
  const pdfContainerRef = useRef<HTMLDivElement | null>(null);

  //   Not ideal here lad - but its a demo
  const { data, isLoading, error } = useFactSheetDemoPDF(0, true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [scale, setScale] = useState(1);
  const [customZoom, setCustomZoom] = useState('100');
  const MIN_SCALE = 0.25;
  const MAX_SCALE = 4.0;
  const SCALE_STEP = 0.1;

  const handleZoomIn = () => {
    const newScale = Math.min(scale + SCALE_STEP, MAX_SCALE);
    setScale(newScale);
    setCustomZoom(Math.round(newScale * 100).toString());
  };

  const handleZoomOut = () => {
    const newScale = Math.max(scale - SCALE_STEP, MIN_SCALE);
    setScale(newScale);
    setCustomZoom(Math.round(newScale * 100).toString());
  };

  const handleResetZoom = () => {
    setScale(1);
    setCustomZoom('100');
  };

  const handleCustomZoomChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value.replace(/[^\d]/g, '');
    setCustomZoom(value);
  };

  const handleCustomZoomBlur = () => {
    let numValue = parseInt(customZoom, 10);
    if (isNaN(numValue)) numValue = 100;

    numValue = Math.max(Math.min(numValue, MAX_SCALE * 100), MIN_SCALE * 100);
    const newScale = numValue / 100;

    setScale(newScale);
    setCustomZoom(numValue.toString());
  };

  const handleCustomZoomKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleCustomZoomBlur();
    }
  };

  const onDocumentLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
    // Once PDF is loaded, restore scroll position:
    if (pdfContainerRef.current) {
      pdfContainerRef.current.scrollTop = scrollPosRef.current;
    }
  };

  // Add near other event handlers
  const handleScroll = () => {
    if (pdfContainerRef.current) {
      scrollPosRef.current = pdfContainerRef.current.scrollTop;
    }
  };

  const OS = getOS();

  function addPDFToViewer() {
    if (!data) return;

    const blob = new Blob([data], { type: 'application/pdf' });
    const fileUrl = window.URL.createObjectURL(blob);

    const iframe = document.querySelector('iframe');
    if (iframe?.src) {
      iframe.src = fileUrl;
      iframe.title = 'Mersenne 01 Fact Sheet';
    }

    if (pdfUrl) URL.revokeObjectURL(pdfUrl);

    // Setting the worker for pdfjs:
    if (OS != 'linux') {
      pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    }

    setPdfUrl(fileUrl);
  }

  useEffect(() => {
    addPDFToViewer();
    updateControlBar();

    return () => {
      dispatch(updateControlBarComponents([]));
      dispatch(toggleControlBarShowing(false));
    };
  }, [data]);

  const updateControlBar = () => {
    const componentsList = [
      <BackToOverviewBtn
        backToOverview={() => navigate(RaidrRoutePaths.FACT_SHEETS_OVERVIEW)}
        dontSetPosition
        key="back_to_overview"
      />,
      <div className="mx-2 max-h-[1.88rem] w-full whitespace-nowrap font-semibold text-mainblue">
        <h6 className="text-lg font-semibold">Mersenne 01 - Fact Sheet</h6>
      </div>,
    ];

    dispatch(updateControlBarComponents(componentsList));
    dispatch(toggleControlBarShowing(true));
  };

  const renderLoadingUI = (
    <div className="mx-auto my-2.5 flex h-[calc(100vh-11.5rem)] w-full items-center justify-center bg-gray-200 shadow-md">
      <Raptor2Loading centerWrap messages={['Generating Document...']} />
    </div>
  );

  return (
    <div className="mx-4 mb-10 w-[calc(100%-2rem)] rounded-md bg-white shadow-md">
      <div className="flex min-h-12 items-center justify-between border-b brder-gray-200 bg-white p-2 rounded-t-md">
        {/* Left group - Zoom controls */}
        <div className="flex w-full items-center gap-2">
          <div className="flex items-center gap-1">
            <Tooltip title="Zoom Out" arrow placement="top">
              <span>
                <Button
                  className={`h-7 w-7 min-w-[28px] border border-gray-200 p-1 hover:bg-gray-500 disabled:cursor-not-allowed disabled:border-gray-100`}
                  variant="outlined"
                  onClick={handleZoomOut}
                  disabled={scale <= MIN_SCALE}
                >
                  <ZoomOutIcon fontSize="small" />
                </Button>
              </span>
            </Tooltip>

            <div className="relative flex h-7 w-16 items-center rounded border border-gray-300">
              <input
                className={`h-full w-full rounded border-none p-1 text-center text-sm outline-none focus:ring-2 focus:ring-blue-500`}
                value={customZoom}
                onChange={handleCustomZoomChange}
                onBlur={handleCustomZoomBlur}
                onKeyDown={handleCustomZoomKeyPress}
              />
              <span className="absolute right-1 text-xs text-gray-500">%</span>
            </div>

            <Tooltip title="Zoom In" arrow placement="top">
              <span>
                <Button
                  className={`h-7 w-7 min-w-[28px] border border-gray-200 p-1 hover:bg-gray-500 disabled:cursor-not-allowed disabled:border-gray-100`}
                  variant="outlined"
                  onClick={handleZoomIn}
                  disabled={scale >= MAX_SCALE}
                >
                  <ZoomInIcon fontSize="small" />
                </Button>
              </span>
            </Tooltip>

            <Tooltip title="Reset Zoom" arrow placement="top">
              <span>
                <Button
                  className={`h-7 w-7 min-w-[28px] border border-gray-200 p-1 hover:bg-gray-500 disabled:cursor-not-allowed disabled:border-gray-100`}
                  variant="outlined"
                  onClick={handleResetZoom}
                  disabled={scale === 1}
                >
                  <RestartAltIcon fontSize="small" />
                </Button>
              </span>
            </Tooltip>
          </div>

          <div className="ml-auto flex items-center gap-1">
            <button
              onClick={() => {
                if (!pdfUrl) return;
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.download = 'Mersenne_sample_fact_sheet.pdf'; // Desired filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              className={`hover:bg-mainblue-dark flex items-center justify-center gap-2 rounded-sm bg-mainblue px-3 py-1 text-center text-xs text-white transition hover:bg-sky-800 focus:bg-sky-800 md:mx-auto`}
            >
              <span className="hidden font-bold md:inline">
                Download PDF
                <DownloadForOfflineIcon className="ml-3 text-[1.25rem]" />
              </span>
              <DownloadForOfflineIcon className="text-[1.25rem] md:hidden" />
            </button>
          </div>
        </div>
      </div>
      <div
        className="flex h-[calc(100vh-11.5rem)] w-full justify-around overflow-auto rounded-b-md bg-gray-200"
        ref={pdfContainerRef}
        onScroll={handleScroll}
      >
        {error ? (
          <div className="mx-auto my-2.5 flex h-full w-full items-center justify-center bg-white shadow-md">
            <ErrorBoundaryMessage />
          </div>
        ) : (
          <>
            {OS == 'linux' ? (
              <>
                <iframe
                  src=""
                  width={isLoading ? '0%' : '100%'}
                  height="100%"
                  title={'kid.pdf'}
                ></iframe>
                {isLoading ? (
                  <Raptor2Loading
                    centerWrap
                    messages={['Generating Document...']}
                  />
                ) : null}
              </>
            ) : (
              <>
                {(isLoading || !pdfUrl) && renderLoadingUI}
                {pdfUrl && !error && (
                  <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => console.error("PDF load error:", error)}
                    loading={renderLoadingUI}
                    className="flex w-full flex-col items-center bg-gray-200"
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        className="my-4 block w-fit bg-white shadow-md"
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        width={750 * scale}
                        renderMode="canvas"
                      />
                    ))}
                  </Document>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FactSheetViewer;
