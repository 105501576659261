import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SearchBar from '../../../../ui/inputs/SearchBar';
import { useFactSheetDemoPDF } from '../services/queries';
import LoadingButton from '../../../../ui/LoadingButton';
import { toast } from '../../../../toast/Toast';
import factSheetPdf from '../static_files/Mersenne_sample_fact_sheet_orange.pdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { RaidrRoutePaths } from '../../../../../routes/page-routes';

// This is the initial Fact Sheet demo viewer. It currently has a static list of funds, but in the future we will have a dynamic list of funds from the API.

const STATIC_DEMO_FUNDS = [
  {
    id: 'mersenne001',
    name: 'Mersenne Scala Fund',
  },
];

const FactSheetsOverview: FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const filteredFunds = STATIC_DEMO_FUNDS.filter((fund) => {
    return (
      fund.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      fund.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // This does nothing for now - we are just hardcoding fetch data as theres non available
  // const { data, isPending, error } = useFactSheetOverviewData();

  const { data, isLoading, error, refetch } = useFactSheetDemoPDF();

  // if (isPending) {
  //   return <TableLoader title={'Loading Fact Sheets...'} />;
  // }

  // if (error) {
  //   return <ErrorBoundaryMessage />;
  // }

  // if (!data) {
  //   return <NoDataMessage />;
  // }

  async function downloadDemoPDF() {
    try {
      const response = await refetch();

      // Check if the response is OK
      if (!response.data || response.isError) {
        throw new Error('Failed to fetch the file.');
      }

      // Directly use the data as it's already a Blob
      const blob = response.data;
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor tag to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Mersenne_sample_fact_sheet.pdf'; // Desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      toast({
        title: 'Download failed:',
        message: `There was an error downloading the file. Please try again later.`,
        type: 'error',
      });
      console.error('Download failed:', error);
    }
  }

  const headers = ['Fund ID', 'Fund Name', 'View Version', 'Download'];

  return (
    // TODO: Rework this with Tanstack table/compact ultra. Maybe this is overkill for Raidr, but try at least
    <div className="h-full rounded-2xl border border-gray-200 bg-white">
      <FactSheetHeader setSearchTerm={setSearchTerm} />
      <div className="w-full overflow-x-auto">
        <table className="w-full table-fixed rounded-lg border border-gray-200 shadow-sm">
          <thead className="hidden md:table-header-group">
            <tr>
              {headers.map((header) => (
                <th
                  key={header}
                  className="w-1/3 border-b border-mainblue p-2 text-center text-sm font-bold text-mainblue md:p-5 md:text-base"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredFunds.map((fund) => (
              <tr
                key={fund.id}
                className="mb-4 block rounded border border-gray-200 p-3 shadow-sm md:table-row md:p-0"
              >
                {/* Fund ID */}
                <td
                  className="flex justify-between p-2 text-sm md:table-cell md:p-3 md:text-center"
                  data-label="Fund ID"
                >
                  <span className="font-medium md:hidden">Fund ID:</span>
                  {fund.id}
                </td>

                {/* Fund Name */}
                <td
                  className="flex justify-between p-2 text-sm md:table-cell md:p-3 md:text-center"
                  data-label="Fund Name"
                >
                  <span className="font-medium md:hidden">Fund Name:</span>
                  {fund.name}
                </td>

                <td
                  className="flex justify-between p-2 text-sm md:table-cell md:p-3 md:text-center"
                  data-label="Fund Name"
                >
                  <span className="font-medium md:hidden">View Version:</span>

                  <div className="flex items-center justify-center">
                    <Link to={RaidrRoutePaths.FACT_SHEETS_VIEW}>
                      <VisibilityIcon className="aspect-square h-8 w-8 cursor-pointer rounded bg-mainblue fill-white p-1 text-lg text-white hover:bg-sky-800 focus:bg-sky-900" />
                    </Link>
                  </div>
                </td>

                {/* Actions */}
                <td
                  className="flex justify-between p-2 text-sm md:table-cell md:p-3 md:text-center"
                  data-label="Actions"
                >
                  <span className="font-medium md:hidden">Download:</span>
                  <a
                    href={factSheetPdf}
                    download="Mersenne_sample_fact_sheet_orange.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`flex w-fit items-center justify-center gap-2 rounded-sm bg-mainblue px-3 py-1 text-center text-sm text-white transition md:mx-auto md:w-[300px]`}
                  >
                    <span className="hidden font-bold md:inline">
                      Download Relevant Files
                      <DownloadForOfflineIcon className="ml-3 text-[1.25rem]" />
                    </span>
                    <DownloadForOfflineIcon className="text-[1.25rem] md:hidden" />
                  </a>

                  {/* This is for the future API */}
                  {/* <LoadingButton
                    onClick={downloadDemoPDF}
                    isLoading={isLoading}
                    className={`flex items-center justify-center gap-2 rounded-sm px-3 py-1 text-center text-sm text-white transition md:mx-auto ${
                      error
                        ? 'bg-red-600 hover:bg-red-700'
                        : 'bg-mainblue hover:bg-sky-800'
                    } ${isLoading ? 'cursor-not-allowed opacity-70' : ''}`}
                  >
                    <span className="hidden font-bold md:inline">
                      Download All Files
                      <DownloadForOfflineIcon className="ml-3 text-[1.25rem]" />
                    </span>
                    <DownloadForOfflineIcon className="text-[1.25rem] md:hidden" />
                  </LoadingButton> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FactSheetsOverview;

interface FactSheetHeaderProps {
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const FactSheetHeader: FC<FactSheetHeaderProps> = ({ setSearchTerm }) => {
  const { isLoading, error, refetch } = useFactSheetDemoPDF(1);

  // Duplicate of the function in the parent component - so we have unique instance of refetch, not good but its not a big deal as we will eventually have proper query keys for the different docs/funds/etc

  async function downloadDemoPDF() {
    try {
      const response = await refetch();

      // Check if the response is OK
      if (!response.data || response.isError) {
        throw new Error('Failed to fetch the file.');
      }

      // Directly use the data as it's already a Blob
      const blob = response.data;
      const url = URL.createObjectURL(blob);

      // Create a temporary anchor tag to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Mersenne_sample_fact_sheet.pdf'; // Desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      toast({
        title: 'Download failed:',
        message: `There was an error downloading the file. Please try again later.`,
        type: 'error',
      });
      console.error('Download failed:', error);
    }
  }

  return (
    <div className="flex flex-wrap items-center justify-between gap-3 rounded-lg p-4 py-6 shadow-sm">
      {/* Left Section */}
      <div className="flex min-w-[250px] flex-wrap items-center gap-3">
        <div className="rounded bg-mainblue px-4 py-0.5 text-sm font-semibold text-white shadow">
          English
        </div>
        <h1 className="text-lg font-medium text-mainblue">
          Fact Sheets Overview
        </h1>
      </div>

      {/* Right Section */}
      <div className="flex w-full min-w-[250px] flex-wrap justify-between gap-3 md:w-fit md:items-center">
        <SearchBar
          searchHandler={setSearchTerm}
          className="w-full py-1 md:w-auto"
        />
        <a
          href={factSheetPdf}
          download="Mersenne_sample_fact_sheet_orange.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className={`flex items-center justify-center gap-2 rounded-sm bg-mainblue px-3 py-1 text-center text-sm text-white transition md:mx-auto`}
        >
          <span className="hidden font-bold md:inline">
            Download All Files
            <DownloadForOfflineIcon className="ml-3 text-[1.25rem]" />
          </span>
          <DownloadForOfflineIcon className="text-[1.25rem] md:hidden" />
        </a>
        {/* <LoadingButton
          onClick={downloadDemoPDF}
          isLoading={isLoading}
          className={`flex items-center justify-center gap-2 rounded-sm px-3 py-1 text-center text-sm text-white transition ${
            error
              ? 'bg-red-600 hover:bg-red-700'
              : 'bg-mainblue hover:bg-sky-800'
          } ${isLoading ? 'cursor-not-allowed opacity-70' : ''}`}
        >
          <span className="flex items-center font-bold">
            <span className="hidden md:inline">Download Relevant Files</span>
            <span className="md:hidden">All</span>
            <DownloadForOfflineIcon className="ml-3 text-[1.25rem]" />
          </span>
        </LoadingButton> */}
      </div>
    </div>
  );
};
